import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddUserClientNotificationTokenMutationVariables = Types.Exact<{
  token: Types.Scalars['String']['input'];
}>;


export type AddUserClientNotificationTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'addUserClientNotificationToken'>
);


export const AddUserClientNotificationTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddUserClientNotificationToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"token"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addUserClientNotificationToken"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"token"},"value":{"kind":"Variable","name":{"kind":"Name","value":"token"}}}]}]}}]} as unknown as DocumentNode;
export type AddUserClientNotificationTokenMutationFn = Apollo.MutationFunction<AddUserClientNotificationTokenMutation, AddUserClientNotificationTokenMutationVariables>;

/**
 * __useAddUserClientNotificationTokenMutation__
 *
 * To run a mutation, you first call `useAddUserClientNotificationTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserClientNotificationTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserClientNotificationTokenMutation, { data, loading, error }] = useAddUserClientNotificationTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAddUserClientNotificationTokenMutation(baseOptions?: Apollo.MutationHookOptions<AddUserClientNotificationTokenMutation, AddUserClientNotificationTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserClientNotificationTokenMutation, AddUserClientNotificationTokenMutationVariables>(AddUserClientNotificationTokenDocument, options);
      }
export type AddUserClientNotificationTokenMutationHookResult = ReturnType<typeof useAddUserClientNotificationTokenMutation>;
export type AddUserClientNotificationTokenMutationResult = Apollo.MutationResult<AddUserClientNotificationTokenMutation>;
export type AddUserClientNotificationTokenMutationOptions = Apollo.BaseMutationOptions<AddUserClientNotificationTokenMutation, AddUserClientNotificationTokenMutationVariables>;