import NetworkOffline from '@/common/components/NetworkOffline';
import { SuspenseWithSpinner } from '@/common/components/SuspenseWithSpinner';
import { ApplicationProvider } from '@/context/ApplicationContext';
import { AuthProvider } from '@/context/AuthContext';
import { GraphqlProvider } from '@/context/GraphqlContext';
import { NotificationProvider } from '@/context/NotificationContext';
import { TemplateProvider } from '@/context/TemplateContext';
import RegisterPushToken from '@/modules/notifications/hooks/useClientNotification';
import { useNetworkStatus } from '@/utils/hooks/useNetworkStatus';
import { Outlet } from '@remix-run/react';
import Navigation from './components/Navigation';

export default function AppLayout() {
  const { isNetworkConnected } = useNetworkStatus();

  return (
    <>
      <AuthProvider>
        <GraphqlProvider>
          <ApplicationProvider>
            <TemplateProvider>
              <NotificationProvider>
                <Navigation>
                  {isNetworkConnected ? (
                    <SuspenseWithSpinner>
                      <Outlet />
                    </SuspenseWithSpinner>
                  ) : (
                    <NetworkOffline />
                  )}
                </Navigation>
              </NotificationProvider>
            </TemplateProvider>
          </ApplicationProvider>
          <RegisterPushToken />
        </GraphqlProvider>
      </AuthProvider>
    </>
  );
}
